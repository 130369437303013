import React from 'react';
import emailjs from 'emailjs-com';

export default function ContactUs() {

  function sendEmail(e) {
    e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it


    var serviceId= 'service_wtiqi7r';
    var templateId = 'template_xb47fer';
    var publicKey = 'GS9hiSa-FhTK_FA03';

    emailjs.sendForm(serviceId, templateId, e.target, publicKey)
      .then((result) => {
          window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
      }, (error) => {
          console.log(error.text);
      });
  }

  return (

        <form id="contact-form" className="contact-form"  right onSubmit={sendEmail}>
       
            <div className="row fw-normal text-start">
                <div className="col-4">
                    <div className="row" id="first-name">
                        <div className="form-group">
                            <label>First Name*</label>
                            <input type="text" className="form-control" name = "from_firstname"  required />
                            <p></p>
                        </div>
                    </div>
                    <div className="row" id="last-name">
                        <div className="form-group">
                            <label>Last Name*</label>
                            <input type="text" className="form-control" name = "from_lastname"  required />
                            <p></p>
                        </div>
                    </div>
                    <div className="row" id="company">
                        <div className="form-group">
                            <label>Company</label>
                            <input type="text" className="form-control" name="from_company" />
                            <p></p>
                        </div>
                    </div>
                    <div className="row" id="email">
                        <div className="form-group">
                            <label>Email*</label>
                            <input type="email" className="form-control" name = "from_email" aria-describedby="emailHelp"  required />
                            <p></p>
                        </div>
                    </div>
                    <div className="row" id="phone">
                        <div className="form-group">
                            <label>Phone</label>
                            <input type="phone" className="form-control" name = "from_phone" aria-describedby="phoneHelp" />
                            <p></p>
                        </div>
                    </div>               
                </div>


                <div className="col-8">
                    <div className="row" id="subject">
                        <div className="form-group">
                            <label>Subject</label>
                            <select class="form-select" name="subject" aria-label="Default select example">
                                <option selected>Getting started</option>
                                <option value="How it works">How it works</option>
                                <option value="Payments">Payments</option>
                                <option value="Support">Support</option>
                                <option value="Feedback">Feedback</option>
                                <option value="Partnership">Partnership</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                    </div>
                    <div className="row" id="message">
                        <div className="form-group">
                            <p></p>
                            <textarea className="form-control" rows="9" name="message" />
                        </div>
                    </div>

                    <div className='text-center'>
                        <p></p>
                        <div class="btn-group-toggle" data-toggle="buttons">
                            <button type="submit" className="btn btn-secondary btn-lg">Send</button>
                        </div>
                    </div>
                       
                </div>       
            </div>
            
        </form>
 
   
  );
}