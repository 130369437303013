import React, { Component } from 'react'
import CelGPS from "../../assets/images/cel-gps.png"
import Email from "../../assets/images/Email.png"
import CarPhoto from "../../assets/images/carphoto.png"
import AddressPhoto from "../../assets/images/addressphoto.png"
import AccountFunded from "../../assets/images/accountfunded.png"
import PaymentMethodPhoto from "../../assets/images/paymentmethodphoto.png"
import AccountActivityPhoto from "../../assets/images/accountactivityphoto.PNG"
import TollPricesPhoto from "../../assets/images/tollpricesphoto.PNG"
import VadoMap from "../../assets/images/vadomap.jpg"
import TollPrices from "../../assets/images/tollprices.jpg"
import LaneClosure from "../../assets/images/laneclosure.jpg"
import ParkingLot from "../../assets/images/parking-lot.jpg"
import AccountSummaryOne from "../../assets/images/account-summary-one.png"
import AccountSummaryTwo from "../../assets/images/account-summary-two.png"
import PairTag from "../../assets/images/pairtag.jpg"





export default class Solutions extends Component {
  constructor(props) {
    super(props)

    this.state = {
      windowWidth: window.innerWidth
    }
  }

  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth});
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  

  render() {
    const renderPaySwitch = () => {
      if (this.windowWidth <= 991){
        return (
          <>
            <div className='col-lg-6 d-flex flex-column justify-content-center'>
            <h1 className='text-title'>
            Pay Your Way.
            </h1>
            <p className='text-content'>
              Multiple payment options for a convenient experience.
            </p>
          </div>
            <div className={`col-lg-6 image-parent ${this.state.windowWidth <= 991 ? 'text-center' : 'text-start'} `} style={{marginTop: '80px', marginBottom: '30px'}}>
              <img className='shadow-image payment-method-photo' src={PaymentMethodPhoto}></img>
              <img className='shadow-image account-funded-photo' src={AccountFunded}></img>
            </div>
          </>
        )
      } else {
        return (
          <>
          <div className={`col-lg-6 image-parent ${this.state.windowWidth <= 991 ? 'text-center' : 'text-start'} `} style={{marginTop: '80px', marginBottom: '30px'}}>
              <img className='shadow-image payment-method-photo' src={PaymentMethodPhoto}></img>
              <img className='shadow-image account-funded-photo' src={AccountFunded}></img>
            </div>
            <div className='col-lg-6 d-flex flex-column justify-content-center'>
            <h1 className='text-title'>
            Pay Your Way.
            </h1>
            <p className='text-content'>
              Multiple payment options for a convenient experience.
            </p>
            </div>
          </>
        )
      }
    }

    return (
      <>
      <section className='overflow-hidden border-bottom shadow-lg' style={{paddingTop:"80px", paddingBottom: "80px", backgroundColor: "rgb(102, 157, 179, .35)"}}>
        <div className="px-4 pt-5 text-center">
          <h1 className="display-4 fw-bold" style={{marginBottom:"40px"}}>Getting Started is a Breeze.</h1>
          <div className="col-lg-6 mx-auto" style={{paddingBottom:"45px"}}>
            <p className="lead ">Upload vehicle info, add a payment method, and you're good to go.</p>
            <p className="lead ">Request a VADO toll tag for free and unlock up to 20% in additional savings.</p>
          </div>
          <div className="w-max row overflow-hidden justify-content-center" style={{paddingBottom: "15px", paddingTop: "15px"}}>
            <div className="col-lg-3 col-sm-6 col-xs-6 my-1 disappear-xs">
              <img src={Email} className="img-fluid shadow-image sign-up" alt="Example image" style={{backgroundColor: "white"}} />
            </div>
            <div className="col-lg-3 col-sm-6 col-xs-6 my-1 disappear-xs ">
              <img src={CarPhoto} className="img-fluid rounded-3 shadow-lg sign-up" alt="Example image" loading="lazy" />
            </div>
            <div className="col-lg-3 col-sm-6 col-xs-6 my-1 disappear-xs ">
              <img src={PairTag} className="img-fluid rounded-3 shadow-lg sign-up" alt="Example image" loading="lazy" style={{backgroundColor: "white"}} />
            </div>
            <div className="col-lg-3 col-sm-6 col-xs-6 my-1 disappear-xs ">
              <img src={AccountFunded} className="img-fluid rounded-3 shadow-lg sign-up" alt="Example image" loading="lazy" style={{backgroundColor: "white"}} />
            </div>

          </div>
        </div>
      </section>

      <section className='overflow-hidden' id="1" style={{paddingBottom:`${this.state.windowWidth <= 991 ? "20px" : "80px"}`, paddingTop: `${this.state.windowWidth <= 991 ? "20px" : "80px"}`}}>
        <div className='col-12 w-max h-max row justify-content-center' /*</section>style={{paddingTop:!this.props.mobile?"70px":"80px"}}*/>
          <div className={`col-lg-7 d-flex flex-column justify-content-center ${this.state.windowWidth <= 991 ? 'text-center' : 'text-start'}`}>
           <h1 className='text-title'>
           All Your Information in One Spot.
           </h1>
           <p className='text-content'>
              Organize, track, and manage your activity with ease.
           </p>
          </div>
          <div className={`col-lg-5 image-parent justify-content-center ${this.state.windowWidth <= 991 ? 'text-center' : 'text-start'}`}>
            <img className='shadow-image account-summary-one-photo' src={AccountSummaryOne}></img>
            <img className='shadow-image account-summary-two-photo' src={AccountSummaryTwo}></img>
          </div>
        </div>
      </section>

      <section className='background-theme overflow-hidden' style={{paddingBottom:`${this.state.windowWidth <= 991 ? "40px" : "80px"}`, paddingTop: `${this.state.windowWidth <= 991 ? "40px" : "80px"}`}}>
        <div className={`col-12 w-max h-max row reverse-flex-responsive justify-content-center ${this.state.windowWidth <= 991 ? 'text-center' : 'text-start'} `} >
        <div className={`col-lg-6 image-parent ${this.state.windowWidth <= 991 ? 'text-center' : 'text-start'} `} style={{paddingTop: '80px', paddingBottom: '30px'}}>
            <img className='shadow-image payment-method-photo' src={PaymentMethodPhoto}></img>
            <img className='shadow-image account-funded-photo' src={AccountFunded}></img>
          </div>
          <div className='col-lg-6 d-flex flex-column justify-content-center'>
          <h1 className='text-title'>
          Pay Your Way.
          </h1>
          <p className='text-content'>
            Multiple payment options for a convenient experience.
          </p>
          </div>
        </div>
      </section>

    {/* <section className='overflow-hidden border-bottom' 
    
    
    
    "2" style={{paddingTop:"50px", paddingBottom: "50px", backgroundColor: "rgb(102, 157, 179, .35)"}}>
        <div className="px-4 pt-5 text-center">
          <h1 className="display-4 fw-bold">Information at Your Fingertips.</h1>
          <div className="col-lg-6 mx-auto" style={{paddingBottom:"45px"}}>
            <p className="lead ">
              Take the uncertainty out of your drive.
            </p>
          </div>
        </div>
      </section> */}

    <section className='overflow-hidden' style={{paddingBottom:`${this.state.windowWidth <= 576 ? "20px" : "80px"}`, paddingTop: `${this.state.windowWidth <= 576 ? "20px" : "80px"}`}}>
    <div className={`col-12 w-max h-max row justify-content-center ${this.state.windowWidth <= 991 ? 'text-center' : 'text-start'} `} >
        <div className='col-lg-6 d-flex flex-column justify-content-center'>
         <h1 className='text-title'>
         Smart Highways.
         </h1>
         <p className='text-content'>
          We coordinate with our transit partners to improve the flow of traffic in your city.
          </p>
        </div>
        <div className={`col-lg-6 image-parent ${this.state.windowWidth <= 991 ? 'text-center' : 'text-start'} `}>
            <img className='shadow-image toll-info-photo' src={LaneClosure}></img>
            <img className='shadow-image lane-closure-photo' src={TollPrices}></img>
          </div>
      </div>
    </section>

    <div className="position-relative border-bottom overflow-hidden text-center shadow-lg vado-section-banner" id="parking">
          <div className="bg-image-three " style={{backgroundImage: `url(${ParkingLot})`, backgroundRepeat: 'no-repeat', backgroundPosition: '50% 20%'}} ></div>
          <div className="col-md-6 p-lg-6 mx-auto banner-text-white" style={{marginTop: "125px"}}>
            <h1 className="display-4 fw-normal banner-header" >Coming Soon</h1>
            <p className="display-5 fw-normal">Parking Made Simple</p>
          </div>
          <div className="product-device shadow-sm d-none d-md-block"></div>
          <div className="product-device product-device-2 shadow-sm d-none d-md-block"></div>
      </div>

    <section className='overflow-hidden' style={{paddingBottom:`${this.state.windowWidth <= 576 ? "20px" : "80px"}`, paddingTop: `${this.state.windowWidth <= 576 ? "20px" : "80px"}`}}>
    <div className={`col-12 w-max h-max row reverse-flex-responsive justify-content-center ${this.state.windowWidth <= 991 ? 'text-center' : 'text-start'} `} >
        <div className='col-lg-5 image-parent'>
         <img className='shadow-image payment-method-photo' src={CelGPS}></img>
        </div>
        <div className='col-lg-7 d-flex flex-column justify-content-center' style={{marginBottom: '30px'}}>
         <h1 className='text-title'>
         Reserve a Parking spot.
         </h1>
         <p className='text-content me-5'>
           View availability and prices in real time.  Reserve your spot ahead of time!
         </p>
        </div>
      </div>
    </section>
    </>
    )
  }
}
