import React, { Component } from 'react'

class NotFound extends Component {
  render() {
    return (
      <div className='content-center'>
        <h2>Not Found Page</h2> 
      </div>
    )
  }
}
export default NotFound;
