import { useMediaQuery } from '@mui/material';
import React, { Component } from 'react'
import { useNavigate } from 'react-router-dom';
import Logo from "../../assets/images/nav__logo.png"
import LogoText from "../../assets/images/vadologotext.png"
import { FiMenu } from "react-icons/fi";
import VadoLogoNew from "../../assets/images/vadologonew.PNG"


class ContentHeader extends Component {
  render() {
    let location = window.location.pathname
    return (
      <nav class="navbar navbar-expand-md fixed-top " style={{backgroundColor: '#FAFAFA', height:'100px'}}>
        <div class="container-fluid" style={{backgroundColor: "#FAFAFA"}}>
          <div>
            <a href='/'>
              <img className='logo' src={VadoLogoNew}></img>
              <img className='logo-text' src={LogoText}></img>
            </a>
          </div>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse text-end content-end" id="navbarCollapse">
            <ul class="navbar-nav ms-auto mb-2 mb-md-0">
              <li class="nav-item">
                <a class="nav-link" aria-current="page" href="/">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/solutions">Solutions</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href='/about-us'>About Us</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href='/contact'>Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}

function Header(props) {
  let navigate = useNavigate();
  const matches = useMediaQuery('(min-width:768px)');
  return <ContentHeader {...props}  mobile={matches} navigate={navigate} />;
}
export default Header;
