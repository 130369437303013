import React, { Component } from 'react'
export default class InformComponent extends Component {
  render() {
    return (
      <div className='col-lg-4 col-md-6 mt-5'>
        <div className={`d-flex flex-column auto info-simple ${this.props.card?"card":null}`}>
          {this.props.icon}
          <h3 className='info-simple-title text-center'>
          {this.props.title}
          </h3>
          <p className='text-content text-center info-simple-content'>
          {this.props.content}
          </p>
        </div>
      </div>
    )
  }
}
