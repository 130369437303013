import React, { Component } from 'react'
import axios from 'axios';

export default class ContactForm extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
          name: '',
          email: '',
          message: ''
        }
      }

      handleSubmit(e){
        e.preventDefault();
        axios({
          method: "POST",
          url:"http://localhost:3002/send",
          data:  this.state
        }).then((response)=>{
          if (response.data.status === 'success') {
            alert("Message Sent.");
            this.resetForm()
          } else if (response.data.status === 'fail') {
            alert("Message failed to send.")
          }
        })
      }
      resetForm(){
        this.setState({name: "", email: "", message: ""})
      }
      
    
      
  
      render() {
        return(
          <div className="App">
            <form id="contact-form" action="vadomobility@neology.net" onSubmit={this.handleSubmit.bind(this)} method="POST">
              <div className="form-group">
                <label className="lead fw-normal" htmlFor="name" >Name</label>
                <input type="text" className="form-control" placeholder="Required" required value={this.state.name} onChange={this.onNameChange.bind(this)} />
              </div>
              <div className="form-group">
                <label className="lead fw-normal" htmlFor="company">Company</label>
                <input type="text" className="form-control" value={this.state.company} onChange={this.onCompanyChange.bind(this)} />
              </div>
              <div className="form-group">
                <label className="lead fw-normal" htmlFor="exampleInputEmail1">Email address</label>
                <input type="email" className="form-control" aria-describedby="emailHelp" placeholder="Required" required value={this.state.email} onChange={this.onEmailChange.bind(this)} />
              </div>
              <div className="form-group">
                <label className="lead fw-normal" htmlFor="subject">Subject </label>
                <select class="form-select"  aria-label="Default select example" onChange={this.onSubjectChange.bind(this)}>
                    <option selected>Getting started</option>
                    <option value="1">How it works</option>
                    <option value="2">Payments</option>
                    <option value="3">Support</option>
                    <option value="3">Feedback</option>
                    <option value="3">Partnership</option>
                    <option value="3">Other</option>
                </select>
                </div>
              <div className="form-group">
                <label className="lead fw-normal" htmlFor="message">Message</label>
                <textarea className="form-control" rows="5" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
              </div>
              <div>
                <p> </p>
              </div>
              <button type="submit" className="btn btn-primary">Send</button>
            </form>
          </div>
        );
      }
    
    onNameChange(event) {
        this.setState({name: event.target.value})
        }
    onCompanyChange(event) {
            this.setState({company: event.target.value})
            }
    onEmailChange(event) {
        this.setState({email: event.target.value})
        }
    onSubjectChange(event) {
            this.setState({subject: event.target.value})
            }
    onMessageChange(event) {
        this.setState({message: event.target.value})
        }
        
       
}
