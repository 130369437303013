import React, { Component } from 'react'
import banner2 from "../../assets/images/banner2.png"
import { Place } from '@mui/icons-material'




class Privacy extends Component {

  constructor(props){
    super(props)

    this.state = {
      dat: {},
      bat: ''
    }
  }

  render() {
    return (
      <>
      <div className="position-relative overflow-hidden text-center shadow-lg vado-banner">
          <div className="bg-image-two " style={{backgroundImage: `url(${banner2})`, backgroundRepeat: 'no-repeat', backgroundPosition: '50% 75%'}} ></div>
          <div className="col-md-6 p-lg-6 mx-auto banner-text-grey" style={{marginTop: "175px"}} >
            <h1 className="display-4 fw-normal banner-header">Privacy Policy</h1>
            {/* <a class="btn btn-outline-secondary" href="#">Coming soon</a> */}
          </div>
      </div>

      <section className='overflow-hidden' style={{paddingBottom:"80px", paddingTop:"40px", marginTop: "100px"}}>
        <div className='col-12 w-max row reverse-flex-responsive' /*</section>style={{paddingTop:!this.props.mobile?"70px":"80px"}}*/>
          <p>Neology, Inc. (“Company” or “We”) respects your privacy and are committed to protecting it through our compliance with this policy. This policy describes:
          </p>
          
          <div className="indent">
            <ul>
                <li>The types of information we may collect or that you may provide when you download, install, register with, access, or use the Vado App (the “App”).</li>
                <li>Our practices for collecting, using, maintaining, protecting, and disclosing that information.</li>
            </ul>
          </div>

          <p>This policy applies only to information we collect in this App and in email, text, and other electronic communications sent through or in connection with this App.
          </p>
          <p>
          </p>
          <p>This policy DOES NOT apply to information that:
          </p>
          <div className="indent">
            <ul>
                <li>We collect offline or on any other Company apps or websites, including websites you may access through this App.</li>
                <li>You provide to or is collected by any third party (see Third-Party Information Collection).</li>
            </ul>
          </div>
          <p>Our websites and apps, and these other third parties have their own privacy policies, which we encourage you to read before providing information on or through them.
          </p>
          <p>
          </p>
          <p>Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, do not download, register with, or use this App. By downloading, registering with, or using this App, you agree to this privacy policy. This policy may change from time to time (see Changes to Our Privacy Policy). Your continued use of this App after we revise this policy means you accept those changes, so please check the policy periodically for updates.
          </p>
          <p></p><p></p>
        
          <h4>Children Under the Age of 16</h4>
          <p>
          The App is not intended for children under 16 years of age, and we do not knowingly collect personal information from children under 16. If we learn we have collected or received personal information from a child under 16 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 16, please contact us at 
          </p>
          <p></p>
          <p></p>
          <h4>Information We Collect and How We Collect It</h4>
          <div className="indent">
            <ul>
                <li>Directly from you when you provide it to us.</li>
                <li>Automatically when you use the App.</li>
            </ul>
          </div>
          <p>Information You Provide to Us<br></br>
             When you download, register with, or use this App, we may ask you provide information:</p>
          <div className="indent">
            <ul>
                <li>By which you may be personally identified, such as name, postal address, email address, telephone number, Toll Tag ID, vehicle license plate number and registration state, vehicle label, and any other identifier by which you may be contacted online or offline (“personal information”).</li>
            </ul>
          </div>
          <p>This information includes:</p>
          <div className="indent">
            <ul>
                <li>Information that you provide by filling in forms in the App. This includes information provided at the time of registering to use the App. We may also ask you for information when you report a problem with the App.</li>
                <li>Records and copies of your correspondence (including email addresses and phone numbers), if you contact us.</li>
                <li>Details of transactions you carry out through the App and of the fulfillment of your orders. You may be required to provide financial information before placing an order through the App.</li>
                <li>Information provided to us by Google or Facebook when you utilize their authentication services to open an account through the App.</li>
            </ul>
          </div>
          <p>Automatic Information Collection and Tracking,<br></br>
             When you download, access, and use the App, it may use technology to automatically collect</p>
          <div className="indent">
            <ul>
                <li>Usage Details. When you access and use the App, we may automatically collect certain details of your access to and use of the App, including traffic data, location data, logs, and other communication data and the resources that you access and use on or through the App.</li>
                <li>Device Information. We may collect information about your mobile device and internet connection, including the device’s unique device identifier, IP address, operating system, browser type, mobile network information, and the device’s telephone number.</li>
                <li>Stored Information and Files. The App also may access metadata and other information associated with other files stored on your device. This may include, for example, photographs, audio and video clips, personal contacts, and address book information.</li>
                <li>Location Information. When enabled by you, this App collects real-time information about the location of your device. The location information collected also consists of activity related to toll use.</li>
                <li>Transaction Activity. We may also collect information about your history of toll transactions as recorded through the applicable Department of Transportation and the App in order to provide services such as activity validation, fee collection and to provide you with reports about your toll usage.</li>
            </ul>
          </div>
          <p>If you do not want us to collect this information, do not download the App or delete it from your device.</p>
          <p>Information Collection and Tracking Technologies<br></br>
             The technologies we use for automatic information collection may include:</p>
          <div className="indent">
            <ul>
                <li>Cookies (or mobile cookies). A cookie is a small file placed on your smartphone. It may be possible to refuse to accept mobile cookies by activating the appropriate setting on your smartphone. However, if you select this setting you may be unable to access certain parts of our App.</li>
                <li>Web Beacons. Pages of the App and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related app statistics (for example, recording the popularity of certain app content and verifying system and server integrity).</li>
            </ul>
          </div>
          <p>Third-Party Information Collection<br></br>
          When you use the App or its content, certain third parties may use automatic information collection technologies to collect information about you or your device. These third parties may include:</p>
          <div className="indent">
            <ul>
                <li>Google Maps. If location services are enabled, your device may share information with Google related to your location and toll, traffic, and road conditions. To opt-out of this collection, you may disable location services in the App.</li>
                <li>Government Agencies. Company may be required to share certain information with the government agencies, for example the Washington Department of Transportation, related to toll activity.</li>
                <li>Advertisers and ad servers.</li>
                <li>Analytics companies, such as Google Analytics</li>
                <li>Your mobile device manufacturer.</li>
            </ul>
            <p>These third parties may use tracking technologies to collect information about you when you use this App. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites, apps, and other online services websites. We do not control these third parties’ tracking technologies or how they may be used.
            </p>
          </div>
          <p></p>
          <p></p>
          <h4>How We Use Your Information</h4>
          <p>We use information that we collect about you or that you provide to us, including any personal information, to:
          </p>
          <div className='indent'>
            <ul>
                    <li>Provide you with the App and its contents, and any other information, products or services that you request from us, including the ability to view and edit personal information.</li>
                    <li>Identify the user of an account, the toll tag, or the vehicle when toll-related services are used.</li>
                    <li>To track toll activity and fees linked to a specific user in order to process your requests.</li>
                    <li>Fulfill any other purpose for which you provide it.</li>
                    <li>Give you notices about your account and to provide you with access to customer support tools, including expiration and renewal notices.</li>
                    <li>Carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.</li>
                    <li>Notify you when App updates are available, and of changes to any products or services we offer or provide though it.</li>
            </ul>
         </div>
          <p>The usage information we collect helps us to improve our App and to deliver a better and more personalized experience by enabling us to:</p>
          <div className='indent'>
            <ul>
                    <li>Estimate our audience size and usage patterns.</li>
                    <li>Store information about your preferences, allowing us to customize our App according to your individual interests</li>
                    <li>Speed up your searches.</li>
                    <li>Recognize you when you use the App.</li>
            </ul>
         </div>
         <p>We use location information we collect to provide you with services such as providing you with information on nearby toll fees, road alerts and conditions, fee collection, activity reports related to your toll use.</p>
         <p></p>
         <p></p>
         <h4>Disclosure of Your Information</h4>
         <p>We may disclose aggregated information about our users, and information that does not identify any individual or device, without restriction.<br></br>
            In addition, we may disclose personal information that we collect or you provide:</p>    
        <div className='indent'>
            <ul>
                    <li>To our subsidiaries and affiliates.</li>
                    <li>To contractors, service providers, and other third parties we use to support our business.</li>
                    <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Neology Inc.’s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Neology, Inc. about our App users is among the assets transferred.</li>
                    <li>To fulfill the purpose for which you provide it.</li>
                    <li>For any other purpose disclosed by us when you provide the information.</li>
                    <li>With your consent.</li>
                    <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
                    <li>To enforce our rights arising from any contracts entered into between you and us, including the App Terms of Use and for billing and collection.</li>
                    <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Neology, Inc., our customers or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</li>
            </ul>
        </div>
        <p></p>
        <p></p>

        <h4>Your Choices About Our Collection, Use, and Disclosure of Your Information</h4>
        <p>We strive to provide you with choices regarding the personal information you provide to us. This section describes mechanisms we provide for you to control certain uses and disclosures of over your information.</p>
        <div className='indent'>
            <ul>
                    <li>Tracking Technologies. You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. You can choose whether or not to allow the App to collect information through other tracking technologies by contacting us at vadomobility@neology.net. If you disable or refuse cookies or block the use of other tracking technologies, some parts of the App may then be inaccessible or not function properly.</li>
                    <li>Location Information. You can choose whether or not to allow the App to collect and use real-time information about your device’s location through the device’s privacy settings or through the APP itself. If you block the use of location information, some parts of the App may become inaccessible or not function properly.</li>
                    <li>Targeted Advertising by the Company. If you do not want us to use information that we collect or that you provide to us to deliver advertisements according to our advertisers’ target-audience preferences, you can opt-out by contacting us at vadomobility@neology.net. You can also always adjust your user advertising preferences by contacting us via the feedback contact form available within the Vado mobile app.</li>
                    <li>Disclosure of Your Information for Third-Party Advertising and Marketing. If you do not want us to share your personal information with unaffiliated or non-agent third parties for advertising and marketing purposes, you can opt-out by contacting us at vadomobility@neology.net. You can also always opt-out by contacting us via the feedback contact form available within the Vado mobile app.</li>
            </ul>
        </div>
        <p>We do not control third parties’ collection or use of your information to serve interest-based advertising. However these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative (“NAI”) on the NAI’s website.</p>
        
        <p></p>
        <p></p>
        <h4>Accessing and Correcting Your Personal Information</h4>
        <p>You can review and change your personal information by logging into the App and visiting your account profile page.<br></br>
           You may also contact us via the feedback contact form available within the Vado mobile app or contacting Customer Support: vadomobility@neology.net.</p>
        <p></p>
        <p></p>
        <h4>Data Security</h4>
        <p>We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure.<br></br>
        The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our App, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.
        Unfortunately, the transmission of information via the internet and mobile platforms is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted through our App. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures we provide.
        </p>
        <p></p>
        <p></p>
        <h4>Changes to Our Privacy Policy</h4>
        <p>We may update our privacy policy from time to time. If we make material changes to how we treat our users’ personal information, we will post the new privacy policy on this page with a notice that the privacy policy has been updated the next time you use the App after we make the change.<br></br>
        The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you and for periodically visiting this privacy policy to check for any changes.
        </p>
        <p></p>
        <p></p>
        <h4>Changes to Our Privacy Policy</h4>
         <p>To ask questions or comment about this privacy policy and our privacy practices, contact us at:</p>
         <p>13520 Evening Creek Drive E<br></br>
            Suite 460<br></br>
            San Diego, CA 92128</p>
         
         
         </div>


        
      </section>

    
      </>
    )
  }
}
export default Privacy