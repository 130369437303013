import React, { Component } from 'react'
import ButtonVado from '../components/button'
import InformComponent from '../components/inforComponent'
import CarToll from "../../assets/svg/car-toll.svg"
import CarsThree from "../../assets/svg/cars-three.svg"
import PCircle from "../../assets/svg/p-circle.svg"
import Route from "../../assets/svg/route.svg"
import Sheef from "../../assets/svg/sheef.svg"
import GasPayment from "../../assets/svg/gas-payment.svg"
import CelGPS from "../../assets/images/cel-gps.png"
import PhotoCar from "../../assets/images/Photo-car.png"
import Apple from "../../assets/images/apple.png"
import GooglePlay from "../../assets/images/google-play.png"
import CarStop from "../../assets/svg/car-stop.svg"
import CelDashboard from "../../assets/images/Dashboard.png"
import VadoMap from "../../assets/images/vadomap.jpg"
import SeattlePic from "../../assets/images/seattle.jpg"
import SeattlePicTwo from "../../assets/images/seattlepictwo.jpg"
import AccountSummaryTwo from "../../assets/images/account-summary-two.png"


import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom'


class ContentHome extends Component {

  constructor(props) {
    super(props)

    this.state = {
      windowWidth: window.innerWidth
    }
  }

  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth});
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  

  render() {
    console.log(this.props.mobile)
    console.log(this.state.windowWidth)
    // this.setState({
    //   windowWidth: window.screen.width
    // })
    // console.log(this.state.windowWidth)
    return (  
      <>
      <div className="position-relative border-bottom overflow-hidden text-center shadow-lg vado-banner" style={{paddingLeft:'20px', paddingRight: '20px', height: '740px'}}>
          <div className="bg-image " style={{backgroundImage: `url(${SeattlePicTwo})`, backgroundPosition: this.state.windowWidth <= 576 ? '20% 30%' : '50% 20%'}} ></div>
          <div className="col-md-6 p-lg-6 mx-auto banner-text-grey banner-text-grey-margin">
            <h1 className="display-4 fw-normal banner-header">Get Back to the Open Road.</h1>
            <p className="lead fw-normal">Toll payment services, parking, fleet data insights.  All on one platform.</p>
            {/* <a class="btn btn-outline-secondary" href="#">Coming soon</a> */}
            
            <div className="lineUp">
              
                <h2 className="display-7">Get $10 when you sign up</h2>
                <p>*for limited time only</p>
            
            </div>
            
            <div className='d-flex flex-row justify-content-center'>
              <a href="https://apps.apple.com/us/app/vado-mobility/id1545182574" target="_blank">
                <img className='shadow-image me-4 mt-4' src={Apple}></img>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.neology.vado&hl=en_US&gl=US" target="_blank">
                <img className='shadow-image mt-4' src={GooglePlay}></img>
              </a>
            </div>
          </div>

          
          <div className="product-device shadow-sm d-none d-md-block"></div>
          <div className="product-device product-device-2 shadow-sm d-none d-md-block"></div>
      </div>

      {/* <section className='col-12 row w-max' style={{marginTop:!this.props.mobile?"70px":"133px",marginBottom:!this.props.mobile?"70px":"150px"}}>
        <div className='col-lg-5'>
          <h1 className='text-title'>
          Get Back to the Open Road.
          </h1>
          <p className='text-content'>
          Toll payment services, parking, fleet data insights.  All on one platform.
          </p>
          <div className='d-flex flex-row'>
            <img className='shadow-image me-4 mt-4' src={Apple}></img>
            <img className='shadow-image mt-4' src={GooglePlay}></img>
           </div>
        </div>
        <div className='col-lg-7 d-flex flex-row-reverse'>
          <img className='image-car-fisrts' src={CarToll}></img>
        </div>
      </section> */}

      <section className='overflow-hidden' style={{paddingBottom:`${this.state.windowWidth <= 576 ? "20px" : "80px"}`, paddingTop: `${this.state.windowWidth <= 576 ? "20px" : "80px"}`, marginTop: "80px"}}>
        <div className="col-12 w-max row" >
          <div className='col-lg-5 d-flex justify-content-center' style={{marginBottom: '30px'}}>
           <img className='shadow-image image-dashboard' src={CelDashboard}></img>
          </div>
          <div className= {`col-lg-7 d-flex flex-column justify-content-center ${this.state.windowWidth <= 576 ? 'text-center' : 'text-start'}`}>
           <h1 className='text-title' style={{fontSize:  `${this.state.windowWidth <= 576 ? '24px' : '48px'}`}}>
           An Integrated Mobility Platform.
           </h1>
           <p className='text-content' style={{fontSize: `${this.state.windowWidth <= 576 ? '12px' : '16px'}`}}>
            We link all the services you need in one convenient app.
           </p>
           <ButtonVado title={"Get Started"} url={"/solutions/#1"} />
          </div>
        </div>
      </section>

      {/* <section className='background-theme overflow-hidden' style={{marginBottom:!this.props.mobile?"70px":"112px"}}>
        <div className='col-12 w-max row reverse-flex-responsive' style={{paddingTop:!this.props.mobile?"70px":"80px",maxWidth:"1050px"}}>
          <div className='col-lg-7 d-flex flex-column justify-content-center'>
           <h1 className='text-title'>
           Track tolls and charges easily.
           </h1>
           <p className='text-content me-5'>
           Alliance is a non-profit public-private partnership of state agencies and trucking industry leaders devoted to safe, secure and efficient use of North America’s highway system. 
           </p>
           <ButtonVado title={"read more"}/>
          </div>
          <div className='col-lg-5 d-flex flex-row-reverse'>
           <img className='shadow-image image-car-second' src={CelDashboard}></img>
          </div>
        </div>
      </section> */}

      <section className='background-theme overflow-hidden' style={{paddingBottom:`${this.state.windowWidth <= 576 ? "20px" : "80px"}`, paddingTop: `${this.state.windowWidth <= 576 ? "20px" : "80px"}`, marginTop: "80px"}}>
        <div className='col-12 w-max row reverse-flex-responsive' /*</section>style={{paddingTop:!this.props.mobile?"70px":"80px"}}*/>
          <div className={`col-lg-7 d-flex flex-column justify-content-center ${this.state.windowWidth <= 576 ? 'text-center' : 'text-start'}`}>
           <h1 className='text-title' style={{fontSize:  `${this.state.windowWidth <= 576 ? '24px' : '48px'}`}}>
           Connect with your city.
           </h1>
           <p className='text-content' style={{fontSize: `${this.state.windowWidth <= 576 ? '12px' : '16px'}`}}>
           VADO coordinates with local transit authorties and traffic information providers to bring you up to the minute information on your travels. 
           </p>
           <ButtonVado title={"Read More"} url={"/solutions/#2"} />
          </div>
          <div className='col-lg-5 d-flex flex-row-reverse' style={{marginBottom: '30px'}}>
           <img className='img-fluid shadow-image image-vado-map' src={VadoMap}></img>
          </div>
        </div>
      </section>

        <section className='overflow-hidden' style={{paddingBottom:`${this.state.windowWidth <= 576 ? "20px" : "80px"}`, paddingTop: `${this.state.windowWidth <= 576 ? "20px" : "80px"}`, marginTop: "80px"}}>
          <div className='col-12 w-max row' /*</section>style={{paddingTop:!this.props.mobile?"70px":"80px"}}*/>
          <div className='col-lg-5 d-flex' style={{marginBottom: '30px'}}>
           <img className='img-fluid shadow-image image-vado-map' src={AccountSummaryTwo}></img>
          </div>
          <div className={`col-lg-7 d-flex flex-column justify-content-center ${this.state.windowWidth <= 576 ? 'text-center' : 'text-start'}`} >
              <h1 className='text-title' style={{fontSize:  `${this.state.windowWidth <= 576 ? '24px' : '48px'}`}}>
                Save Time and Money
              </h1>
              <p className='text-content' style={{fontSize:  `${this.state.windowWidth <= 576 ? '12px' : '16px'}`}}>
                Instant tolling. No more mail-in toll bills, cash lane or toll violation. Save up to 20% on your toll bills and collect redeemable points from all your trips.
              </p>
              <p className='text-content'>
                <strong>Sign up now to unlock $10 reward!</strong>                
              </p>
              <ButtonVado title={"Read More"} url={"/solutions/#1"} />
            </div>
        </div>
      </section>
      </>
    )
  }
}

function Home(props) {
  let navigate = useNavigate();
  const matches = useMediaQuery('(min-width:768px)');
  return <ContentHome {...props}  mobile={matches} navigate={navigate} />;
}
export default Home;
