import React, { Component } from 'react'
import RFIDPhoto from "../../assets/images/rfidphoto.jpg"
import Manu from "../../assets/images/manu.jpg"
import HighwayPhoto from "../../assets/images/highwayphoto.jpg"
import banner2 from "../../assets/images/banner2.png"
import Vado_service_map from "../../assets/images/Vado_service_map.svg"
import WSDOTLogo from "../../assets/images/wsdotlogo.png"
import PeachPassLogo from "../../assets/images/peachpass.png"
import Fastrak from "../../assets/images/fastrak.png"
import Neil from "../../assets/images/neil.jpg"
import Cesar from "../../assets/images/cesar.png"
import Roman from "../../assets/images/roman.jpg"
import Victor from "../../assets/images/victor.png"
import Eduardo from "../../assets/images/eduardo.png"
import CesarCastillo from "../../assets/images/cesar-castillo.png"
import Jorge from "../../assets/images/jorge.png"
import Will from "../../assets/images/will.jpg"
import Francis from "../../assets/images/francis.jpg"
import Omar from "../../assets/images/omar.png"
import Arturo from "../../assets/images/arturo.png"
import Gio from "../../assets/images/gio.png"
import Diego from "../../assets/images/diego.png"
import PlaceholderPhoto from "../../assets/images/placeholderphoto.png"
import NeologyPhotoTransparent from "../../assets/images/neology-logo-transparent.png"
import { Place } from '@mui/icons-material'




export default class AboutAs extends Component {

  constructor(props) {
    super(props)

    this.state = {
      windowWidth: window.innerWidth
    }
  }

  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth});
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    return (
      <>
      <div className="position-relative overflow-hidden text-center shadow-lg vado-banner">
          <div className="bg-image-two " style={{backgroundImage: `url(${HighwayPhoto})`, backgroundRepeat: 'no-repeat', backgroundPosition: '50% 75%'}} ></div>
          <div className="col-md-5 p-lg-5 mx-auto banner-text-white" style={{marginTop: "175px"}} >
            <h1 className="display-4 fw-normal banner-header">Get to Know VADO.</h1>
            <p className="lead fw-normal">Toll payment services, parking, fleet data insights.  All on one platform.</p>
            {/* <a class="btn btn-outline-secondary" href="#">Coming soon</a> */}
          </div>
      </div>

      <section className='overflow-hidden' style={{paddingBottom:"80px", paddingTop:"40px", marginTop: "100px"}}>
      <div className={`col-12 w-max h-max row reverse-flex-responsive justify-content-center ${this.state.windowWidth <= 991 ? 'text-center' : 'text-start'} `} >
          <div className='col-lg-7 d-flex flex-column justify-content-center'>
            <h1 className='text-title' style={{fontSize:  `${this.state.windowWidth <= 576 ? '24px' : '48px'}`}}>
            What We Do
            </h1>
            <p className='text-content' style={{fontSize: `${this.state.windowWidth <= 576 ? '12px' : '16px'}`}}>
            We re-imagine mobility to accelerate your vision of a smart city and safer communities.
            </p>
            <p className='text-content' style={{fontSize: `${this.state.windowWidth <= 576 ? '12px' : '16px'}`}}>
            By closely collaborating with our customers, we connect existing infrastructure assets with next-generation technology to
             modernize the way people move. Backed by a culture of innovation, we help create safer, cleaner, more efficient mobility
              experiences that support sustainable growth and help communities thrive. 
            </p>
          </div>
          <div className='col-lg-5 d-flex' style={{marginBottom: '65px'}}>
           <img className='img-fluid border shadow-image image-partners' src={NeologyPhotoTransparent} style={{marginRight: '25px', paddingRight: '20px', paddingLeft: '20px', paddingTop: '25px', paddingBottom: '25px'}}></img>
           <img className='img-fluid shadow-image image-partners' src={WSDOTLogo} style={{marginRight: '25px'}}></img>
          </div>
        </div>
      </section>

      {/* <section className='background-theme overflow-hidden' style={{paddingBottom:"40px", paddingTop:"40px"}}>
        <div className="col-12 w-max h-max row align-items-center" >
          <div className='col-lg-6 h-max d-flex align-items-center text-center'>
            <div className="col-12 row justify-content-center flex-row-reverse">            
              <div className="col-lg-4">
                <card>
                  <img className='shadow-image image-wsdot' src={WSDOTLogo}  />
                </card>
              </div>
              <div className="col-lg-4">
                <card>
                  <img className='shadow-image image-logo' src={PeachPassLogo} />
                </card>
              </div>
              <div className="col-lg-4">
                <card>
                  <img className='shadow-image image-logo' src={Fastrak} />
                </card>
              </div>
            </div>
          </div>
          <div className='col-lg-6 d-flex flex-column justify-content-center'>
            <h1 className='text-title'>
            Our Partners.
            </h1>
            <p className='text-content me-5'>
              We work with leading transit authorities in cities across the globe.
              Nearly half of the country's highways and bridges have switched to cashless tolling. You can save up to 50% by having a transponder.  
            </p>
          </div>
        </div>
      </section> */}


      
<section className='overflow-hidden' style={{paddingBottom:"80px", paddingTop:"40px", marginTop: "100px"}}>
      <div className='col-12 w-max row' /*</section>style={{paddingTop:!this.props.mobile?"70px":"80px"}}*/>
      <div className= {`col-lg-5 d-flex flex-column justify-content-center ${this.state.windowWidth <= 576 ? 'text-center' : 'text-start'}`}>
            <h1 className='text-title' style={{fontSize:  `${this.state.windowWidth <= 576 ? '24px' : '48px'}`}}>
            Where VADO Works
            </h1>
            {/*
            <ul class="list-group list-group-flush text-content me-5">
              <li class="list-group-item">I-405 Express Lanes</li>
              <li class="list-group-item">I-5 Express Lanes</li>
              <li class="list-group-item">SR 167 HOT Lanes</li>
              <li class="list-group-item">SR 520 Bridge</li>
              <li class="list-group-item">SR 99 Tunnel</li>
              <li class="list-group-item">Tacoma Narrows Bridge</li>
    </ul>*/}
            
          </div>
          <div className='col-lg-7 d-flex flex-row-reverse'>
          </div>

          <p></p>
          <p></p>
          
          <img className='image-servicemap' src={Vado_service_map}></img>
      </div>
</section>





      <section className='overflow-hidden background-theme border-bottom shadow-sm' style={{paddingTop:"80px", paddingBottom: "80px"}}>
        <div className="px-4 pt-5 text-center">
          <h1 className="display-4 fw-bold">Meet the Vado Team.</h1>
          <div className="col-lg-6 mx-auto" style={{paddingBottom:"45px"}}>
            <p className="lead ">The People Making it Happen.</p>
          </div>
        </div>
      </section>

      <section className='overflow-hidden' style={{paddingTop:"80px", paddingBottom: "30px"}}>
        <div className="photo-circle text-center">
          <img className="rounded-circle shadow-4-strong portrait-photo" src={Neil}/>
        </div>
        <div className="px-4 pt-5 text-center">
          <h1 className="team-name fw-bold">Neil</h1>
          <div className="col-lg-6 mx-auto" style={{paddingBottom:"45px"}}>
            <p className="team-name-desc lead ">Chief Digital Officer and General Manager</p>
          </div>
        </div>
      </section>

      <section className='overflow-hidden' style={{paddingTop:"30px", paddingBottom: "30px"}}>
        <div className="col-12 row w-max d-flex justify-content-center">
          <div className="col-lg-4">
            <div className="photo-circle text-center">
              <img className="rounded-circle shadow-4-strong portrait-photo-second-row" src={Cesar}/>
            </div>
            <div className="px-4 pt-5 text-center">
              <h1 className="team-name fw-bold">Cesar</h1>
              <div className="col-lg-6 mx-auto" style={{paddingBottom:"45px"}}>
                <p className="team-name-desc lead ">Scrum Master</p>
              </div>
            </div>  
          </div>
          <div className="col-lg-4">
            <div className="photo-circle text-center">
              <img className="rounded-circle shadow-4-strong portrait-photo-second-row" src={Gio}/>
            </div>
            <div className="px-4 pt-5 text-center">
              <h1 className="team-name fw-bold">Giovanni</h1>
              <div className="col-lg-6 mx-auto" style={{paddingBottom:"45px"}}>
                <p className="team-name-desc lead ">Operations Director</p>
              </div>
            </div>  
          </div>
          <div className="col-lg-4">
            <div className="photo-circle text-center">
              <img className="rounded-circle shadow-4-strong portrait-photo-second-row" src={Diego}/>
            </div>
            <div className="px-4 pt-5 text-center">
              <h1 className="team-name fw-bold">Diego</h1>
              <div className="col-lg-6 mx-auto" style={{paddingBottom:"45px"}}>
                <p className="team-name-desc lead ">Business Development</p>
              </div>
            </div>  
          </div>
        </div>
        <div className="col-12 row w-max d-flex justify-content-center">
          <div className="col-lg-3">
            <div className="photo-circle text-center image-parent">
              <img className="rounded-circle shadow-4-strong portrait-photo-eduardo" src={Eduardo}/>
            </div>
            <div className="px-4 pt-5 text-center">
              <h1 className="team-name fw-bold">Eduardo</h1>
              <div className="col-lg-6 mx-auto" style={{paddingBottom:"45px"}}>
                <p className="team-name-desc lead ">Software Engineer </p>
              </div>
            </div>  
          </div>
          <div className="col-lg-3">
            <div className="photo-circle text-center">
              <img className="rounded-circle shadow-4-strong portrait-photo-three" src={Jorge}/>
            </div>
            <div className="px-4 pt-5 text-center">
              <h1 className="team-name fw-bold">Jorge</h1>
              <div className="col-lg-6 mx-auto" style={{paddingBottom:"45px"}}>
                <p className="team-name-desc lead ">Software Engineer</p>
              </div>
            </div>  
          </div>
          <div className="col-lg-3">
            <div className="photo-circle text-center image-parent">
              <img className="rounded-circle shadow-4-strong portrait-photo-omar" src={Omar}/>
            </div>
            <div className="px-4 pt-5 text-center">
              <h1 className="team-name fw-bold">Omar</h1>
              <div className="col-lg-6 mx-auto" style={{paddingBottom:"45px"}}>
                <p className="team-name-desc lead ">DevOps Engineer</p>
              </div>
            </div>  
          </div>
          <div className="col-lg-3">
            <div className="photo-circle text-center image-parent">
              <img className="rounded-circle shadow-4-strong portrait-photo-omar" src={Roman}/>
            </div>
            <div className="px-4 pt-5 text-center">
              <h1 className="team-name fw-bold">Roman</h1>
              <div className="col-lg-6 mx-auto" style={{paddingBottom:"45px"}}>
                <p className="team-name-desc lead ">UI/UX Designer</p>
              </div>
            </div>  
          </div>
        </div>
        <div className="col-12 row w-max d-flex justify-content-center">
          <div className="col-lg-3">
            <div className="photo-circle text-center image-parent">
              <img className="rounded-circle shadow-4-strong portrait-photo-three" src={CesarCastillo}/>
            </div>
            <div className="px-4 pt-5 text-center">
              <h1 className="team-name fw-bold">Cesar</h1>
              <div className="col-lg-6 mx-auto" style={{paddingBottom:"45px"}}>
                <p className="team-name-desc lead ">Software Engineer </p>
              </div>
            </div>  
          </div>
          <div className="col-lg-3">
            <div className="photo-circle text-center">
              <img className="rounded-circle shadow-4-strong portrait-photo-three" src={Arturo}/>
            </div>
            <div className="px-4 pt-5 text-center">
              <h1 className="team-name fw-bold">Arturo</h1>
              <div className="col-lg-6 mx-auto" style={{paddingBottom:"45px"}}>
                <p className="team-name-desc lead ">Software Engineer</p>
              </div>
            </div>  
          </div>
          <div className="col-lg-3">
            <div className="photo-circle text-center image-parent">
              <img className="rounded-circle shadow-4-strong portrait-photo-three" src={Francis}/>
            </div>
            <div className="px-4 pt-5 text-center">
              <h1 className="team-name fw-bold">Francis</h1>
              <div className="col-lg-6 mx-auto" style={{paddingBottom:"45px"}}>
                <p className="team-name-desc lead ">Data Engineer</p>
              </div>
            </div>  
          </div>
          <div className="col-lg-3">
            <div className="photo-circle text-center image-parent">
              <img className="rounded-circle shadow-4-strong portrait-photo-three" src={Will}/>
            </div>
            <div className="px-4 pt-5 text-center">
              <h1 className="team-name fw-bold">Will</h1>
              <div className="col-lg-6 mx-auto" style={{paddingBottom:"45px"}}>
                <p className="team-name-desc lead ">Data Engineer</p>
              </div>
            </div>  
          </div>
        </div>
      </section>
      </>
    )
  }
}
