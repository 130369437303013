import React, { Component } from 'react'
import ContactForm from '../components/contactForm'
import Emailjs from '../components/emailjs'
import SeattlePicTwo from "../../assets/images/seattlepictwo.jpg"

export default class Contact extends Component {
  render() {
    return (
      <>
  

      <section className='overflow-hidden border-bottom shadow-lg' style={{paddingTop:"80px", paddingBottom: "80px", backgroundColor: "rgb(102, 157, 179, .35)"}}>
        <div className="px-4 pt-5 text-center">
          <h1 className="display-4 fw-bold">Contact Us</h1>
          <p className="lead fw-normal">Send us questions or stay connected for upcoming features and promotions.</p>
         
          <div className="col-12 w-max row overflow-hidden justify-content-center " style={{paddingBottom: "15px", paddingTop: "15x"}}>
            <div className="col-lg-8 pt-5 text-start">
              <Emailjs />
            </div>
            
          </div>
        </div>
      </section>
      
      
           

         
     
      </>
      
    )
  }
}
